import WalkingFeet from './WalkingFeet'
import { Link } from "react-router-dom";

function Header(props)
{
    return <>
        <div className="header">
            <div className="contentWrapper contentWrapper_header">
                <Link to="/hjem">
                    <WalkingFeet></WalkingFeet>
                    <div className="textWrapper">
                        <h1><span>{props.SiteName.slice(0, props.SiteName.indexOf(" "))}</span><span>{props.SiteName.slice(props.SiteName.indexOf(" ") + 1, props.SiteName.length)}</span></h1>
                        <h5>{props.TagLine}</h5>
                    </div>
                </Link>
            </div>
        </div>
    </>
}

export default Header