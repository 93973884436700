import sundhedshusetGrindsted from '../IMG/SundhedshusetGrindsted.png'

function Socials()
{
    return <>
        <div className="Aside Socials">      

        <div className="fb-page" data-href="https://www.facebook.com/Sundhedshuset-Grindsted-155145515207671/" data-tabs="timeline" data-width="260" data-height="80" data-small-header="false" data-adapt-container-width="true" data-hide-cover="false" data-show-facepile="false"><blockquote cite="https://www.facebook.com/Sundhedshuset-Grindsted-155145515207671/" className="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/Sundhedshuset-Grindsted-155145515207671/">Sundhedshuset Grindsted på Facebook</a></blockquote></div>
        <br/><br/>
        <small>Din fodplejer er en del af sundhedshuset Grindsted.</small>
        </div>
    </>;
}

export default Socials 