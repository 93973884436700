import SundhedshusetGavekort from '../IMG/SundhedshusetGrindstedGavekort.png';
import { useEffect } from "react";
import { useOutletContext } from "react-router-dom";

let menuIndex = 3; 

function Produkter(props){

    let SetNavState = useOutletContext(); 

    useEffect(() => {
        SetNavState(menuIndex);
    });

    return <>
        <img src={SundhedshusetGavekort} alt="Billede af Gavekort fra Sundhedshuset Grindsted"></img>

        <p>
            Der kan købes gavekort til alle lejligheder. 
        </p>
        <p>
            Vi anvender: 
        </p>
        <p>
            Gehwol produkter til almindelig fodpleje.
        </p>
        <p>
            Økologisk panpuri produkter til luksus fodpleje. 
        </p>
        <p>
            Andre produkter vi anvender til hænder fødder og ansigtsmassage er helt naturlige, økologiske og fra urtgården, Naturfarm og DR. Baumann.
        </p>
    </>;
}

export default Produkter