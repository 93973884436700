import { Link } from "react-router-dom";
import { useState } from "react";

// New Subpages needs this bit to let them highlight the correct menu item when openened (See Produkter.js)
// let menuIndex = 3; 

// function Produkter(props){

//     let SetNavState = useOutletContext(); 

//     useEffect(() => {
//         SetNavState(menuIndex);
//     });

const NavItems = [
    {Name: "HJEM", URL: "hjem"},
    {Name: "BEHANDLINGER", URL: "behandlinger"},
    {Name: "KONTAKT", URL: "kontakt"},
    {Name: "PRODUKTER", URL: "produkter"},
];

function Nav(props)
{
    const [navIndex, setNavIndex] = useState(0);

    return <>
        <div className="nav_colorbar"></div>
        <nav className="nav glow_subtle">
            <div className="contentWrapper">
                <ul>
                    {
                        NavItems.map((element, i) => {
                            return <li  className="noselect" key={i}>
                                <Link to={element.URL}>
                                    {i == props.NavIndex ? 
                                    <b>{element.Name}</b> : 
                                    element.Name}
                                </Link>
                            </li>
                        })
                    }
                    <div className="clearFix"></div>
                </ul>
            </div>
        </nav>     
    </>
}

export default Nav