function ContactInfo()
{
    return <>
        <ul className="contactInfo">
            <li>
                Solvej 4
            </li>
            <li>
                <a href="tel:24405231">24 40 52 31</a>
            </li>
            <li>
                7200 Grindsted
            </li>
            <li>
                <a href="mailto:kontakt@dinfodplejer.dk">kontakt @ dinfodplejer.dk</a>
            </li>
        </ul>
    </>;
}

export default ContactInfo; 