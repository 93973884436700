import { useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import Behandling from "../Components/Behandling";

let menuIndex = 1; 

function Behandlinger(props)
{   
    const behandlinger = [
        {id: 0, title:"Fodbehandling", price:"400", text:"Et forfriskende urtefodbad, neglene klippes, files og rettes til. Neglebånd ordnes. hård hud og ligtorne fjernes. Der afsluttes med behagelig fodmassage."},
        {id: 1, title:"Luksus Fodbehandling", price:"550", text:"I denne behandling bruges de lækreste Wellness produkter fra Panpuri. Dejlig fodbad med beroligende virkning. Derefter almindelig fodpleje, Fodbehandlingen afsluttes med en lækker peeling som fjerner døde hudceller og en fodmaske der afsluttes med 10 til 15 minutters afslapning med varm kompress og en dybtegående fodmassage."},
        {id: 2, title:"Japansk Liftning Ansigtsmassage", price:"525", text:"Er en ansigtszoneterapi kombineret med en massage med akupressur og lymfedrænage. Den smidiggøre og afspænder musklerne udglatter huden og øger blodgennemstrømningen, giver farve glød og vitalitet til dit ansigt. Der afsluttes med saltstensmassage og ansigtscreme. Denne behandling virker afstressende på både huden og kroppen en oplevelse for krop og sjæl."},
        {id: 3, title:"Terapeutisk Massage", price:"400", text:"Terapeutisk massage. Er en blid massageform der arbejder med samspillet mellem krop og psyke. Massagen giver dig velvære og nydelse fornyet energi og har en afstressende virkning."},
        {id: 4, title:"Delbehandling (Op til 30 minutter)", price:"250", text:"Beskæring af hård hud, ligtorne eller klipning/slibning af negle. Behandlingen afsluttes med cremepleje. OBS: Overstiger behandlingstiden 30 minutter vil prisen være 350kr"},
        {id: 5, title:"Mini Manicure", price:"150", text:"Til dig som ikke ønsker at klippe dine fingernegle selv. Et lunt håndbad. Neglene bliver klippet. Neglene bliver filet. Let håndmassage. (Larkering kan tilkøbes for 50kr)."},
        {id: 6, title:"Spa Manicure", price:"375", text:"Et lunt håndbad. Neglene bliver klippet. Neglene bliver filet i form. Neglebånd bliver skubbet tilbage. Hænderne bliver masseret godt ind i en effektiv peeling, som skånsormt fjerner alle de døde hudceller. Dejlig håndmassage i fugtgivende håndmaske. evt. lakering eller afsluttende plejende neglebåndsolie. Denne behandling giver sunde og velplejede negle, samt bløde og gennemfugtede hænder."},
        {id: 7, title:"Thai Fodmassage", price:"400", text:"Giv dine fødder lidt ekstra opmærksomhed. Behandlingen starter med et varmt urtefodbad. Fødderne masseres med fingrene og en lille træpind. Denne behandling sætter gang i blodcirkulationen giver lethed i fødderne og velvære i hele kroppen."},
    
    ];

    const tilkoeb = [
        {id: 1, title:"Peeling", price:"50", text:"Peeling er en skånsom massage som fjerner døde hudceller, fremmer blodcirkulationen og efterlader huden glat. "},
        {id: 2, title:"Lakering", price:"50", text:"Farvet lakering af negle med almindelig lak. Lakeringen er 2 lag farvet lak og 1 gang topcoat"},
        {id: 3, title:"Ekstra fodmassage", price:"200", text:"30 minutters ekstra fodmassage. Fodmassage er utrolig afslappende og fantastisk til trætte og ømme fødder. Øger bevægelsesfrihed i fødder og led "},
    ];

    let SetNavState = useOutletContext(); 
    
    useEffect(() => {
        SetNavState(menuIndex);
    });

    return <>
        <h1>Behandlinger</h1>
        <p>Der kan betales med mobilepay eller kontant</p>
        <p>Vi udfører fodbehandlinger i eget hjem, på plejehjem og på sygehuse. Er det ikke muligt at komme i klinikken af fysisk årsag, kommer vi hjem til dig (mod kørselstillæg).</p>
        {behandlinger.map((element, index) => {
            return <Behandling key={index} {...element}></Behandling>
        })}
        <h1>Tilkøb</h1>
        {tilkoeb.map((element, index) => {
            return <Behandling key={index} {...element}></Behandling>
        })}
        <div className="spacer"></div>
    </>;
}

export default Behandlinger