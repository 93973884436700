import bette from '../IMG/Bette.png';
import { useEffect } from "react";
import { useOutletContext } from "react-router-dom";
import ContactInfo from "../Components/ContactInfo";

let menuIndex = 2; 

function Kontakt(props)
{
    let SetNavState = useOutletContext(); 

    useEffect(() => {
        SetNavState(menuIndex);
    });

    return <>
         <div class="spacer"></div>
        <div className="DinFodPlejer">
            <img src={bette} alt="Billede af Bette Holstein" />
            <div className="textWrapper">
                <h1><span>Din &nbsp;</span><span>Fodplejer</span></h1>
                <div className="spacer_small"></div>
                <h5>v/ Elisabeth Holstein</h5>
            </div>
        </div>
        <div class="clearFix"></div>
        <div class="spacer"></div>
        <div class="spacer"></div>
        <ContactInfo></ContactInfo>
        <div className="clearFix"></div>
        <div class="spacer"></div>
        <div className="GMap">
            <iframe title="gmap" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d71844.70135720179!2d8.798984462499993!3d55.75595000000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x464b0cfa73c04fdf%3A0xb5b43fb5f4305244!2sDin%20Fodplejer!5e0!3m2!1sda!2sdk!4v1656379349267!5m2!1sda!2sdk" ></iframe>
        </div>
        <div className="spacer_small"></div>
    </>;
}

export default Kontakt