import './App.css';
import {useEffect} from 'react';
import React from 'react';
import MyRouter from './Components/MyRouter.js';

function App() {

  useEffect(() => {
    // update title (TODO should be moved to subpages)
    document.title = "Din Fodplejer v/ Bette Holstein";    
});

  return <>
    <MyRouter></MyRouter>
  </>
}

export default App;
