import WalkingFeet from "./WalkingFeet"

function ContactBar()
{
    return <>
        <div className="contactBar">
            <div className="contentWrapper contentWrapper_ContactBar">
                <WalkingFeet></WalkingFeet>
                <div className="PhoneNumber">
                    <a href="tel:24405231">24 40 52 31</a>
                </div>
                <div className="Mail">
                    <a href="mailto:Kontakt@dinfodplejer.dk">kontakt @ dinfodplejer.dk</a>
                </div>
                <div className="clearFix"></div>
            </div>
        </div>
    </>
}

export default ContactBar