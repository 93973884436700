import { useState } from 'react';
import Header from '../Components/Header';
import Nav from '../Components/Nav';
import Content  from '../Components/Content';
import ContactBar from "../Components/ContactBar";
import Footer from "../Components/Footer";
import Socials from '../Components/Socials';
import AsideBehandlinger from '../Components/AsideBehandlinger';

function Layout()
{
    const [navState, SetNavState] = useState(0);

    const behandlinger = [
        {title:"Fodbehandling", text:"Et forfriskende urtefodbad, neglene klippes, files og rettes til. Neglebånd ordnes. hård hud og ligtorne fjernes. Der afsluttes med behagelig fodmassage."},
        {title:"Luksus fodbehandling", text:"I denne behandling bruges de lækreste Wellness produkter fra Panpuri. Dejlig fodbad med beroligende virkning. Derefter almindelig fodpleje, Fodbehandlingen afsluttes med en lækker peeling som fjerner døde hudceller og en fodmaske der afsluttes med 10 til 15 minutters afslapning med varm kompress og en dybtegående fodmassage."},
        {title:"Japansk liftning” Ansigtsmassage", text:"Er en ansigtszoneterapi kombineret med en massage med akupressur og lymfedrænage. Den smidiggøre og afspænder musklerne udglatter huden og øger blodgennemstrømningen, giver farve glød og vitalitet til dit ansigt. Der afsluttes med saltstensmassage og ansigtscreme. Denne behandling virker afstressende på både huden og kroppen en oplevelse for krop og sjæl."},
        {title:"Terapeutisk massage", text:"Terapeutisk massage. Er en blid massageform der arbejder med samspillet mellem krop og psyke. Massagen giver dig velvære og nydelse fornyet energi og har en afstressende virkning."},
        {title:"", text:"Se Alle behandlinger"},
    ];

    const siteName = "Din Fodplejer";
    const tagLine = "v/ Elisabeth Holstein";

    return <>
        <Header SiteName={siteName} TagLine={tagLine}></Header>
        <Nav NavIndex={navState}></Nav>
        <div className="contentWrapper">
            <Content SetNavState={SetNavState} behandlinger={behandlinger}>
            </Content>
            <AsideBehandlinger Behandlinger={behandlinger}></AsideBehandlinger>
            <Socials></Socials>
            <div className="clearFix"></div>
        </div>
        <ContactBar></ContactBar>
        <Footer SiteName={siteName} TagLine={tagLine}></Footer>
    </>
}

export default Layout; 