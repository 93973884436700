import { Link } from "react-router-dom";
import bette from '../IMG/Bette.png';
import ContactInfo from "./ContactInfo";

function Footer(props){
    return <>
        <div className="Footer_colorline glow"></div>
        <footer>
            <div className="spacer"></div>
            <div className="contentWrapper">
                <Link to="Hjem">
                    <div className="DinFodPlejer">
                        <img src={bette} alt="Billede af Bette Holstein" />
                        <div className="textWrapper">
                            <h1><span>{props.SiteName.slice(0, props.SiteName.indexOf(" "))}</span><span>{props.SiteName.slice(props.SiteName.indexOf(" ") + 1, props.SiteName.length)}</span></h1>
                            <h5>{props.TagLine}</h5>
                        </div>
                    </div>
                </Link>
                <ContactInfo></ContactInfo>
                <div className="clearFix"></div>
            </div>
            <div className="spacer"></div>
            <div className="spacer"></div>
            <div className="attribution noselect">
                holsteinkruse webdesign @2022 
            </div>
            <div className="attribution noselect">
                © All Rights Reserved 
            </div>
        </footer>
    </>
}

export default Footer