import { HashRouter as Router, Routes, Route } from "react-router-dom";
import Layout from '../pages/Layout';
import Kontakt from '../pages/Kontakt';
import Behandlinger from '../pages/Behandlinger';
import Hjem from "../pages/Hjem";
import Produkter from "../pages/Produkter";

function MyRouter()
{
    return <>
        <Router>
            <Routes>
                <Route exact path="/" element={<Layout />}>
                    <Route index element={<Hjem />} />
                    <Route path="/hjem" element={<Hjem />} />
                    <Route path="/behandlinger" element={<Behandlinger />} />
                    <Route path="/kontakt" element={<Kontakt />} />
                    <Route path="/produkter" element={<Produkter />} />
                    <Route path="*" element={<Hjem />} />
                </Route>
            </Routes>
        </Router>
    </>;
}

export default MyRouter 