import { HashLink as Link } from 'react-router-hash-link';

function BehandlingPreview(props)
{
    
    return <>
        <h5>{props.title}</h5>
        <p>{props.text.slice(0, 100) + "..." }</p>
        <Link to={"/Behandlinger#" + props.id}><div className="btn">Læs Mere</div></Link>
        <div className="clearFix"></div>
    </>;
}

export default BehandlingPreview