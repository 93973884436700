import BehandlingPreview from "./BehandlingPreview";

function AsideBehandlinger(props)
{
    return <>
        <div className="Aside Aside_Behandlinger">
            <div className="Banner">

            </div>

            <h3>Behandlinger</h3>
            {props.Behandlinger.map((element, index)=> {
                return <BehandlingPreview key={index} id={index} {...element}></BehandlingPreview>
            })}
        </div>
    </>;
}

export default AsideBehandlinger