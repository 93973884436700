import { Outlet } from "react-router-dom";
import LoadingScreen from "./LoadingScreen";

function Content(props){

    function setLoaded(element)
    {
        element.classList.add("loaded");
    }

    function removeLoaded()
    {
        Array.from(document.getElementsByClassName("loadingScreen")).forEach((element) => element.classList.remove("loaded"));
    }

    return <>
        <div className="Content" onLoad={()=> Array.from(document.getElementsByClassName("loadingScreen")).forEach((element) => setLoaded(element))}>
            {/*<LoadingScreen></LoadingScreen>*/}
            <Outlet context={props.SetNavState}></Outlet>
        </div>
    </>
}

export default Content