function Behandling(props)
{
    return <>
        <a id={props.id}></a>
        <h3>{props.title}</h3>
        <p>{props.text}</p>
        <div className="price">{props.price + " kr."}</div>
        <div className="clearFix"></div>
    </>;
}

export default Behandling