
function WalkingFeet(props){

    return <>
        <div className="walkingFeetWrapper">
            <div className="walkingFeetWrapper_inner">
                <div className="foot"></div>
                <div className="foot"></div>
                <div className="foot"></div>
                <div className="foot"></div>
                <div className="foot"></div>
                <div className="foot"></div>
                <div className="foot"></div>
                <div className="foot"></div>
                <div className="clearFix"></div>
            </div>
        </div>
    </>
}

export default WalkingFeet;